@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */

$bu: 24px;

body {
  padding: 0;
  margin: 0;

  font-family: Roboto,"Helvetica Neue",sans-serif;
}

.u-text-center {
  text-align: center;
}

.mt-1 {
  margin-top: $bu;
}

.mb-1 {
  margin-bottom: $bu;
}

.w-100 {
  width: 100%;
}